<template>
  <div class="container">
    <!-- <div class="title">{{ newsInfo.title }}</div> -->
    <div class="content" v-html="language === 'cn' ? newsInfo.cnContent : newsInfo.enContent"></div>
  </div>
</template>

<script>
import config from '@/config'
export default {
  name: 'newsInfo',
  components: {},
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  data() {
    return {
      newsInfo: {}
    }
  },
  created() {},
  mounted() {
    const id = this.$route.query.id - 1
    this.newsInfo = config.newsList[id]
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 20px;
  width: 950px;
  margin: 0 auto;
  // line-height: 2.5;
  font-weight: 400;

  .title {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    white-space: pre-line;
  }

  :deep(.content) {
    font-size: 15px;
    white-space: pre-line;
    text-align: justify;
    text-indent: 2em;
    line-height: 1;

    /* .link {
        color: #409eff;
        text-decoration: none;
      } */
  }
}
</style>
